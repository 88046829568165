.browser-warning {
  background-color: @pink-biobot;
  color: @darkblue-biobot;
  position: relative;
  text-align: center;
  line-height: 2rem;
}

.info-banner {
  background-color: @biobotblue-1;
  color: @darkblue-biobot;
  position: relative;
  text-align: center;
  line-height: 2rem;
}

.warning-banner {
  background-color: @red-8;
  color: white;
  position: relative;
  text-align: center;
  line-height: 2rem;
}
