.sign-up {
  padding: 2rem 10rem 3rem;
  max-width: 100rem;
  margin: 0 auto;
}

.sign-up .ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: #0e2d4c;
  font-size: 32px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: none;
}

.bullets {
  list-style-type: none;
}

@media screen and (max-width: 800px) {
  .sign-up {
    max-width: 100%;
    padding: 2rem 2rem;
  }
}
