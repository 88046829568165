.issue-popover {
  width: 300px;
}

.meta-content {
  background-color: white;
}

.meta-content-0 {
  background-color: #eeeeee;
  padding: 4px 8px;
}

.meta-content-1 {
  background-color: #f9f9f9;
  padding: 4px 8px;
}

.popover-issue-title {
  text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em;
}

.action-tag-reject {
  color: #750a00;
  text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em;
}

.action-tag-flag_rerun_sample,
.action-tag-rerun,
.action-tag-review {
  color: #a29f00;
  text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em;
}

.action-tag-flag_log,
.action-tag-warn {
  color: black;
  text-shadow: 0 0.01em, 0.01em 0, 0.01em 0.01em;
}

.meta-content-key {
  text-shadow: 0 0.005em, 0.005em 0, 0.005em 0.005em;
}
