.Auth__wrapper {
  margin: 0;
  height: 100%;
}

.Auth__page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100% 30px;
  grid-template-areas:
    "content"
    "footer";
}
.Auth__content {
  grid-area: content;
  position: relative;
}
.Auth__footer {
  grid-area: footer;
  background-color: #012d4e;
  color: #fff;
  text-transform: uppercase;
  font-size: 10px;
}

.Login__page {
  width: 20rem;
  position: relative;
  background: white;
  padding: 2rem;
  margin: 2rem auto;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 4px rgba(1, 45, 78, 0.088988);
}

.Login__img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: none;
  object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
}
