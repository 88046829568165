.chart-tooltip {
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
}

.chart-tooltip-header {
  text-align: left;
  padding-left: 8px;
}

.chart-tooltip-target-cell {
  text-align: left;
  padding-right: 8px;
}

.chart-tooltip-concentration-cell {
  text-align: right;
}
