/*
 This is a hack to fix the '+' buttons on this page. Solution taken from
 https://github.com/ant-design/ant-design/issues/29303
 */
.ant-table-row-expand-icon {
  transform: none !important;
  width: 16px !important;
  height: 16px !important;
}

.ant-table-row-expand-icon::after {
  display: none !important;
}
.ant-table-row-expand-icon-expanded::before {
  content: "-" !important;
}
.ant-table-row-expand-icon-collapsed::before {
  content: "+" !important;
}
.ant-table-row-expand-icon::before {
  position: unset !important;
  transform: none !important;
  background: inherit !important;
  height: 100% !important;
}

.ant-table-row-expand-icon {
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  line-height: 13px !important;
}
