@0: 0;
@1: 0.5rem;
@2: 1rem;
@3: 1.5rem;
@4: 2rem;
@5: 2.5rem;

.center {
  margin: auto;
}

.m-0 {
  margin: @0;
}
.m-1 {
  margin: @1;
}
.m-2 {
  margin: @2;
}
.m-3 {
  margin: @3;
}
.m-4 {
  margin: @4;
}
.m-5 {
  margin: @5;
}

.mt-0 {
  margin-top: @0;
}
.mt-1 {
  margin-top: @1;
}
.mt-2 {
  margin-top: @2;
}
.mt-3 {
  margin-top: @3;
}
.mt-4 {
  margin-top: @4;
}
.mt-5 {
  margin-top: @5;
}

.mb-0 {
  margin-bottom: @0;
}
.mb-1 {
  margin-bottom: @1;
}
.mb-2 {
  margin-bottom: @2;
}
.mb-3 {
  margin-bottom: @3;
}
.mb-4 {
  margin-bottom: @4;
}
.mb-5 {
  margin-bottom: @5;
}

.ml-0 {
  margin-left: @0;
}
.ml-1 {
  margin-left: @1;
}
.ml-2 {
  margin-left: @2;
}
.ml-3 {
  margin-left: @3;
}
.ml-4 {
  margin-left: @4;
}
.ml-5 {
  margin-left: @5;
}

.ml-auto {
  margin-left: auto;
}

.mr-0 {
  margin-right: @0;
}
.mr-1 {
  margin-right: @1;
}
.mr-2 {
  margin-right: @2;
}
.mr-3 {
  margin-right: @3;
}
.mr-4 {
  margin-right: @4;
}
.mr-5 {
  margin-right: @5;
}

.mx-0 {
  margin-right: @0;
  margin-left: @0;
}
.mx-1 {
  margin-right: @1;
  margin-left: @1;
}
.mx-2 {
  margin-right: @2;
  margin-left: @2;
}
.mx-3 {
  margin-right: @3;
  margin-left: @3;
}
.mx-4 {
  margin-right: @4;
  margin-left: @4;
}
.mx-5 {
  margin-right: @5;
  margin-left: @5;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-bottom: @0;
  margin-top: @0;
}
.my-1 {
  margin-bottom: @1;
  margin-top: @1;
}
.my-2 {
  margin-bottom: @2;
  margin-top: @2;
}
.my-3 {
  margin-bottom: @3;
  margin-top: @3;
}
.my-4 {
  margin-bottom: @4;
  margin-top: @4;
}
.my-5 {
  margin-bottom: @5;
  margin-top: @5;
}

.p-0 {
  padding: @0;
}
.p-1 {
  padding: @1;
}
.p-2 {
  padding: @2;
}
.p-3 {
  padding: @3;
}
.p-4 {
  padding: @4;
}
.p-5 {
  padding: @5;
}

.pt-0 {
  padding-top: @0;
}
.pt-1 {
  padding-top: @1;
}
.pt-2 {
  padding-top: @2;
}
.pt-3 {
  padding-top: @3;
}
.pt-4 {
  padding-top: @4;
}
.pt-5 {
  padding-top: @5;
}

.pb-0 {
  padding-bottom: @0;
}
.pb-1 {
  padding-bottom: @1;
}
.pb-2 {
  padding-bottom: @2;
}
.pb-3 {
  padding-bottom: @3;
}
.pb-4 {
  padding-bottom: @4;
}
.pb-5 {
  padding-bottom: @5;
}

.pl-0 {
  padding-left: @0;
}
.pl-1 {
  padding-left: @1;
}
.pl-2 {
  padding-left: @2;
}
.pl-3 {
  padding-left: @3;
}
.pl-4 {
  padding-left: @4;
}
.pl-5 {
  padding-left: @5;
}

.pr-0 {
  padding-right: @0;
}
.pr-1 {
  padding-right: @1;
}
.pr-2 {
  padding-right: @2;
}
.pr-3 {
  padding-right: @3;
}
.pr-4 {
  padding-right: @4;
}
.pr-5 {
  padding-right: @5;
}

.px-0 {
  padding-left: @0;
  padding-right: @0;
}

.px-1 {
  padding-left: @1;
  padding-right: @1;
}

.px-2 {
  padding-left: @2;
  padding-right: @2;
}

.px-3 {
  padding-left: @3;
  padding-right: @3;
}

.px-4 {
  padding-left: @4;
  padding-right: @4;
}

.px-5 {
  padding-left: @5;
  padding-right: @5;
}

.py-1 {
  padding-top: @1;
  padding-bottom: @1;
}

.py-2 {
  padding-top: @2;
  padding-bottom: @2;
}

.py-3 {
  padding-top: @3;
  padding-bottom: @3;
}

.py-4 {
  padding-top: @4;
  padding-bottom: @4;
}

.py-5 {
  padding-top: @5;
  padding-bottom: @5;
}
