@media all and (min-width: 1500px) {
  .step {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    max-width: 16.65%;
  }
  .step-desc {
    height: 100%;
  }
  .icon-container {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) calc(50% - 1px),
      rgba(192, 192, 192, 1) calc(50%),
      rgba(0, 0, 0, 0) calc(50% + 1px)
    ) !important;
  }
}
@media all and (max-width: 1300px) {
  .copy {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .step-number {
    width: 100%;
  }
  .step-title-desc-container {
    display: flex;
    width: 100%;
  }
  .step-title {
    font-size: 36px;
  }
  .step-desc {
    font-size: 24px;
  }
}
@media all and (max-width: 1010px) {
  .step-title-desc-container {
    flex-wrap: wrap;
  }
  .step-desc {
    width: 100%;
  }
}

.stepper {
  width: 100%;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
}
.step {
  display: flex;
  width: 100%;
}
.icon-container {
  background: linear-gradient(rgba(192, 192, 192), rgba(192, 192, 192))
    no-repeat center/2px 100%;
}
.icon {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: 5px;
  background-color: #00c783;
}
.check {
  margin-left: 10px;
  margin-top: 10px;
  color: white;
  font-size: 30px;
}
.copy {
  margin-bottom: 15px;
}
.final-copy {
  display: flex;
  align-items: center;
}
.step-number {
  font-size: 12px;
  width: 62px;
  display: inline-block;
  margin-left: 5px;
  opacity: 50%;
}
.step-title-desc-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}
.step-title {
  display: inline-block;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 700;
}
.step-desc {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
  padding-right: 50px;
}

.SamplingInfo__wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: #e6f7ff;
}
