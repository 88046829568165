.loader {
  position: static;
  background: white;
  display: flex;
  align-items: center;
  min-height: 364px;
}

.kits-modal .ant-modal-content {
  min-height: 400px;
}

.kits-modal .ant-modal-body {
  min-height: 364px;
}
