@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import "./colors.less";
@import "./spacing.less";
@import "./fonts.less";
@import "./sizing.less";
@import "./display.less";
@import "../../src/components/PageWarningBanners/page-warning-banners.less";

@header-padding: 0 1.5rem 0 1.5rem;
@header-index: 2;
@header-shadow: 0rem 0.2rem 0.2rem rgba(0, 0, 0, 0.25);

@top-1: 0.5rem;
@top-2: 1rem;
@top-3: 1.5rem;
@top-4: 2rem;
@top-5: 2.5rem;

//General

.ant-picker-clear {
  right: 1.25rem;
  opacity: 1;
}

.page {
  top: @top-2;
  max-width: 50%;
}

.full-width {
  width: 100%;
}

.secondary-text__pt-1 {
  display: inline-block;
}

.input__prefix-icon {
  color: @ltgrey-biobot;
}

//<Layout>

.layout__header {
  z-index: @header-index;
  box-shadow: @header-shadow;
  display: flex;
  justify-content: space-between;
}

//<PageHeader>

.pageheader-footer__row-btn-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

// Nav

.sign-out-drop {
  font-size: @font-size-lg;
}

.col__nav-logo-container {
  max-width: 2.1rem;
  overflow: hidden;
}

// Order Form

.order-form__row {
  display: flex;
  justify-content: space-between;
}

// Stepper

.ant-steps-item-title {
  font-size: @font-size-sm;
}

.ant-steps-icon {
  font-size: @font-size-sm;
}

.ant-steps-item-icon {
  line-height: 1.3rem;
}

.mobile-menu__btn {
  font-size: 1.25rem;
  position: absolute;
  top: 1.5rem;
  left: 3rem;
}

@media screen and (min-width: 992px) {
  .mobile-menu__btn {
    left: 12.5rem;
  }
  .col__nav-logo-container {
    overflow: visible;
  }
}

// Form

.biobot-form.biobot-form-disabled * {
  // Adapted from ant-typography-disabled
  &,
  &:active,
  &:hover {
    color: @disabled-color;
    cursor: not-allowed;
    pointer-events: none;
  }
  // Also get the required mark
  & label.ant-form-item-required::before {
    color: @disabled-color;
  }
}

// Move antd required asterisk to top of label text (align-self middle by default)
.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  align-self: flex-start;
  padding-top: ~"max(4px, 0.5%)";
}

// Accommodate longer descriptions in dropdown menu options so they don't use ellipses
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: normal;
  text-overflow: unset;
}

// Give buttons a small border on hover to keep them accessible
.ant-btn-primary:hover {
  border: 1px solid #1890ff;
}

// Darken the select color a bit for more contrast on focus
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #006dd1;
}

// The top right config cog is a "button" for accessibility purposes, but don't want it blue
.anticon-setting {
  color: @biobot-dark-blue;
}

@black: @black-biobot;@font-family: SF Pro, "Roboto", sans-serif;@layout-sider-background: @white-biobot;@layout-header-background: @white-biobot;@layout-header-padding: @header-padding;@layout-body-background: @white;@page-header-heading-title: @heading-2-size;@btn-font-weight: 400;@btn-font-size-lg: 12px;@btn-border-radius-base: 2px;@heading-color: @biobot-dark-blue;@btn-disable-border: @disabled-bg;@btn-disable-color: @btn-primary-color;@text-color: @biobot-dark-blue;@heading-1-size: 38px;@heading-2-size: 30px;@heading-3-size: 24px;@heading-4-size: 20px;@heading-5-size: 16px;