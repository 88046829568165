.w-10 {
  width: 10%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-min {
  width: min-content;
}

.w-fit {
  width: fit-content;
}

.w-max {
  width: max-content;
}

.max-960px {
  max-width: 960px;
}
