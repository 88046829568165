.inline {
  display: inline;
}
.block {
  display: block;
}
.contents {
  display: contents;
}
.flex {
  display: flex;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.grid {
  display: grid;
}
.inline-block {
  display: inline-block;
}
.inline-flex {
  display: inline-flex;
}
.inline-grid {
  display: inline-grid;
}
.inline-table {
  display: inline-table;
}
.list-item {
  display: list-item;
}
.run-in {
  display: run-in;
}
.table {
  display: table;
}
.table-caption {
  display: table-caption;
}
.table-column-group {
  display: table-column-group;
}
.table-header-group {
  display: table-header-group;
}
.table-footer-group {
  display: table-footer-group;
}
.table-row-group {
  display: table-row-group;
}
.table-cell {
  display: table-cell;
}
.table-column {
  display: table-column;
}
.table-row {
  display: table-row;
}
.none {
  display: none;
}
.initial {
  display: initial;
}
.inherit {
  display: inherit;
}
