.text-center {
  text-align: center;
}

// Font sizes. These are the regular antd sizes (sm, base, lg) plus the heading
// sizes h4 (xl) - h1 (4xl), with line-height copied from antd heading source.
// Antd additionally adds margin, color, and weight, so, e.g. text-4xl is not
// exactly the same as h1. These classes should be used with Text in places
// where Title is either not recommended for a11y reasons, or invalid (e.g.
// <label> only accepts phrasing content).
//
//   Instead of this                   Use this
// <Title level={1} />       <Text className="text-4xl" />
// <Title level={2} />       <Text className="text-3xl" />
// <Title level={3} />       <Text className="text-2xl" />
// <Title level={4} />       <Text className="text-xl" />
// <Title level={5} />       <Text className="text-lg" />

.text-4xl {
  font-size: @heading-1-size;
  line-height: 1.23;
}

.text-3xl {
  font-size: @heading-2-size;
  line-height: 1.35;
}

.text-2xl {
  font-size: @heading-3-size;
  line-height: 1.35;
}

.text-xl {
  font-size: @heading-4-size;
  line-height: 1.4;
}

.text-lg {
  // by default this is 16px, the same as heading-5-size
  font-size: @font-size-lg;
  line-height: 1.5;
}

.text-md,
.text-base {
  font-size: @font-size-base;
  line-height: @line-height-base;
}

.text-sm {
  font-size: @font-size-sm;
}

.text-thick {
  font-weight: 900;
}

.text-thin {
  font-weight: 100;
}
